import css from "@emotion/css";
import { Location, RouteComponentProps, Router } from "@reach/router";
import { Box, Flex } from "@rebass/grid/emotion";
import { graphql, navigateTo, useStaticQuery, Link } from "gatsby";
import { values } from "lodash";
import React, { useState } from "react";
import IosInformationCircleOutline from "react-ionicons/lib/IosInformationCircleOutline";
import MdArrowDown from "react-ionicons/lib/MdArrowDown";
import MdShare from "react-ionicons/lib/MdShare";
import { Waypoint } from "react-waypoint";
import {
  Articles,
  Footer,
  Layout,
  Map,
  MapInfo,
  Quotes,
  Select,
  Selector,
  ShareDialog,
  Stats,
  StreetView,
  TimeSelector,
  Loading
} from "~components";
import SEO from "~components/seo";
import districts from "~data/districts";
import { Data, WieBezitOnzeStadComponentProps } from "~src/@types/types";
import { Container } from "~src/components/container";
import { colors, LargeScreen, mq } from "~style";
import { detect } from "detect-browser";

const windowGlobal = typeof window !== "undefined" && window;

const App = () => {
  const data: Data = useStaticQuery(graphql`
    {
      price: allGoogleSheetPriceRow {
        edges {
          node {
            cbsBuurt
            price2008
            price2009
            price2010
            price2011
            price2012
            price2013
            price2014
            price2015
            price2016
            price2017
            price2018
            price2019
          }
        }
      }
      woz: allGoogleSheetWozRow {
        edges {
          node {
            cbsBuurt
            woz2008
            woz2009
            woz2010
            woz2011
            woz2012
            woz2013
            woz2014
            woz2015
            woz2016
            woz2017
            woz2018
            woz2019
          }
        }
      }
      gemeente: allGoogleSheetGemeenteRow {
        edges {
          node {
            cbsBuurt
            gemeente2008
            gemeente2009
            gemeente2010
            gemeente2011
            gemeente2012
            gemeente2013
            gemeente2014
            gemeente2015
            gemeente2017
            gemeente2018
            gemeente2019
          }
        }
      }
      corporaties: allGoogleSheetCorporatiesRow {
        edges {
          node {
            cbsBuurt
            corporaties2008
            corporaties2009
            corporaties2010
            corporaties2011
            corporaties2012
            corporaties2013
            corporaties2014
            corporaties2015
            corporaties2017
            corporaties2018
            corporaties2019
          }
        }
      }
      institutioneleBeleggers: allGoogleSheetInstitutioneleBeleggersRow {
        edges {
          node {
            cbsBuurt
            institutioneleBeleggers2008
            institutioneleBeleggers2009
            institutioneleBeleggers2010
            institutioneleBeleggers2011
            institutioneleBeleggers2012
            institutioneleBeleggers2013
            institutioneleBeleggers2014
            institutioneleBeleggers2015
            institutioneleBeleggers2017
            institutioneleBeleggers2018
            institutioneleBeleggers2019
          }
        }
      }
      particuliereVerhuur19: allGoogleSheetParticuliereVerhuur19Row {
        edges {
          node {
            cbsBuurt
            particuliereVerhuur192008
            particuliereVerhuur192009
            particuliereVerhuur192010
            particuliereVerhuur192011
            particuliereVerhuur192012
            particuliereVerhuur192013
            particuliereVerhuur192014
            particuliereVerhuur192015
            particuliereVerhuur192017
            particuliereVerhuur192018
            particuliereVerhuur192019
          }
        }
      }
      particuliereVerhuur1099: allGoogleSheetParticuliereVerhuur1099Row {
        edges {
          node {
            cbsBuurt
            particuliereVerhuur10992008
            particuliereVerhuur10992009
            particuliereVerhuur10992010
            particuliereVerhuur10992011
            particuliereVerhuur10992012
            particuliereVerhuur10992013
            particuliereVerhuur10992014
            particuliereVerhuur10992015
            particuliereVerhuur10992017
            particuliereVerhuur10992018
            particuliereVerhuur10992019
          }
        }
      }
      particuliereVerhuur100: allGoogleSheetParticuliereVerhuur100Row {
        edges {
          node {
            cbsBuurt
            particuliereVerhuur1002008
            particuliereVerhuur1002009
            particuliereVerhuur1002010
            particuliereVerhuur1002011
            particuliereVerhuur1002012
            particuliereVerhuur1002013
            particuliereVerhuur1002014
            particuliereVerhuur1002015
            particuliereVerhuur1002017
            particuliereVerhuur1002018
            particuliereVerhuur1002019
          }
        }
      }
      eigenaarBewoner: allGoogleSheetEigenaarBewonerRow {
        edges {
          node {
            cbsBuurt
            eigenaarBewoner2008
            eigenaarBewoner2009
            eigenaarBewoner2010
            eigenaarBewoner2011
            eigenaarBewoner2012
            eigenaarBewoner2013
            eigenaarBewoner2014
            eigenaarBewoner2015
            eigenaarBewoner2017
            eigenaarBewoner2018
            eigenaarBewoner2019
          }
        }
      }
      totaal: allGoogleSheetTotaalRow {
        edges {
          node {
            cbsBuurt
            totaal2008
            totaal2009
            totaal2010
            totaal2011
            totaal2012
            totaal2013
            totaal2014
            totaal2015
            totaal2017
            totaal2018
            totaal2019
          }
        }
      }
      quotes: allGoogleSheetQuotesRow {
        edges {
          node {
            name
            title
            text
            cbsBuurt
          }
        }
      }
      streetview: allGoogleSheetStreetviewRow {
        edges {
          node {
            cbsBuurt
            url
          }
        }
      }
    }
  `);

  // console.log("data", data);

  return (
    <Location>
      {({ location }) => {
        return (
          <Router location={location} primary={false}>
            <Page path="/app" data={data} />
            <Page path="/app/:district/:layer/:fromYear/:toYear" data={data} />
          </Router>
        );
      }}
    </Location>
  );
};

type PageProps = WieBezitOnzeStadComponentProps & RouteComponentProps;

const Page = ({
  district = "rotterdam",
  layer = "price",
  fromYear = "2013",
  toYear = "2019",
  data
}: PageProps) => {
  const [showSelector, setShowSelector] = useState(true);
  const [showBottomMenu, setShowBottomMenu] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showScrollArrow, setShowScrollArrow] = useState(true);
  const onMapWaypoint = (visible: boolean) => {
    if (visible) {
      setShowSelector(true);
      setShowBottomMenu(false);
    } else {
      setShowSelector(false);
      setShowBottomMenu(true);
    }
  };
  const onArticlesWaypoint = (visible: boolean) => {
    if (visible) {
      setShowBottomMenu(false);
    } else {
      setShowBottomMenu(true);
    }
  };
  const onTitleWaypoint = (visible: boolean) => {
    if (visible) {
      setShowScrollArrow(true);
    } else {
      setShowScrollArrow(false);
    }
  };
  const selectOptions = values(districts).map(district => ({
    value: district.slug,
    label: district.name
  }));
  if (typeof window !== "undefined") {
    const browser = detect();
    if (browser && browser!.name === "ie") {
      return (
        <Layout>
          <Container>
            Deze site is alleen te bekijken op een moderne browser zoals{" "}
            <a
              css={css`
                color: ${colors.white};
              `}
              href="https://www.mozilla.org/en-US/firefox/new/"
            >
              Firefox
            </a>
            ,{" "}
            <a
              css={css`
                color: ${colors.white};
              `}
              href="https://www.microsoft.com/en-us/windows/microsoft-edge"
            >
              Edge
            </a>{" "}
            of{" "}
            <a
              css={css`
                color: ${colors.white};
              `}
              href="https://www.google.com/chrome/"
            >
              Chrome
            </a>
          </Container>
        </Layout>
      );
    } else {
      // console.log(browser && browser.name);
    }
  }
  return (
    <Layout>
      <SEO title={districts[district!].name} />
      <Container>
        <Waypoint
          onEnter={() => {
            onTitleWaypoint(true);
          }}
          onLeave={() => {
            onTitleWaypoint(false);
          }}
          // @ts-ignore
          scrollableAncestor={windowGlobal}
        >
          <div
            css={css`
              position: absolute;
              top: 0;
              width: 100%;
              height: 20px;
            `}
          />
        </Waypoint>
        {showScrollArrow && (
          <Flex
            justifyContent="center"
            css={css`
              position: fixed;
              bottom: 16px;
              left: 0;
              right: 0;
              z-index: 3;
              pointer-events: none;
            `}
          >
            <MdArrowDown
              css={css`
                background: ${colors.black}8;
                padding: 8px;
                width: 48px;
                height: 48px;
                border-radius: 50%;
              `}
              shake={true}
              color={colors.white}
            />
          </Flex>
        )}
        <div>
          <Flex
            css={css`
              position: relative;
            `}
            flexDirection={["column"]}
          >
            <Flex
              flex={1}
              flexDirection="column"
              justifyContent="flex-end"
              css={css`
                padding: 0;
              `}
            >
              <Box
                css={css`
                  width: 50px;
                  height: auto;
                  position: fixed;
                  top: 0px;
                  right: 0;
                  z-index: 3;
                  background: ${colors.blue2};
                  padding: 8px;
                `}
              >
                <a
                  href="https://versbeton.nl/harde-kern/wie-bezit-onze-stad/"
                  target="_blank"
                  css={css`
                    height: auto;
                    width: 100%;
                  `}
                >
                  <img
                    src={require("../../assets/vers-beton-logo.png")}
                    css={css`
                      width: 100%;
                    `}
                  />
                </a>
                <Link
                  to="/colofon"
                  css={css`
                    height: auto;
                    width: 100%;
                  `}
                >
                  <IosInformationCircleOutline
                    color={colors.white}
                    css={css`
                      width: 100%;
                      height: auto;
                    `}
                  />
                </Link>
                <MdShare
                  onClick={() => setShowShareDialog(true)}
                  color={colors.white}
                  css={css`
                    cursor: pointer;
                    width: 100%;
                    height: auto;
                    padding: 0 4px;
                  `}
                />
              </Box>
              <Box
                css={css`
                  background: ${colors.blue2};
                  text-align: center;
                  margin: 0 50px;
                `}
              >
                <h1
                  css={css`
                    font-size: 1.2rem;
                    ${mq("lg")} {
                      font-size: 3em;
                      margin-bottom: 0px;
                    }
                  `}
                >
                  Had ik toen maar een huis gekocht
                </h1>
                <h2
                  css={css`
                    font-size: 1rem;
                    ${mq("lg")} {
                      font-size: 1.5rem;
                      margin-top: 0;
                    }
                  `}
                >
                  Tien jaar Rotterdamse woningmarkt
                </h2>
              </Box>
              <Waypoint
                onEnter={() => {
                  onMapWaypoint(true);
                }}
                onLeave={() => {
                  onMapWaypoint(false);
                }}
                // @ts-ignore
                scrollableAncestor={windowGlobal}
              >
                <Flex
                  css={css`
                    /* margin: 0 -30%; */
                    ${mq("lg")} {
                      margin: 0;
                      height: calc(100vh - 100px);
                    }
                  `}
                  flexDirection="column"
                  justifyContent="center"
                >
                  {typeof window !== "undefined" && (
                    <Map
                      district={district}
                      layer={layer}
                      fromYear={fromYear}
                      toYear={toYear}
                      data={data}
                      style={{
                        width: "100%"
                      }}
                    />
                  )}
                </Flex>
              </Waypoint>
            </Flex>
            {typeof window !== "undefined" && (
              <>
                <MapInfo
                  cssProps={css`
                    ${mq("lg")} {
                      position: absolute;
                      bottom: 100px;
                    }
                  `}
                  district={district}
                  layer={layer}
                  fromYear={fromYear}
                  toYear={toYear}
                  data={data}
                />
                <Selector
                  cssProps={css`
                    ${mq("lg")} {
                      position: fixed;
                      top: 0;
                      left: ${showSelector ? "0px" : "-300px"};
                      transition: left 1s;
                      width: 300px;
                      z-index: 2;
                    }
                  `}
                  district={district}
                  layer={layer}
                  fromYear={fromYear}
                  toYear={toYear}
                  data={data}
                />
                <Box>
                  <TimeSelector
                    district={district}
                    layer={layer}
                    fromYear={fromYear}
                    toYear={toYear}
                    data={data}
                  />
                </Box>
              </>
            )}
          </Flex>
        </div>
        <Box>
          {typeof window !== "undefined" && (
            <Stats
              district={district}
              layer={layer}
              fromYear={fromYear}
              toYear={toYear}
              data={data}
            />
          )}
        </Box>
        <Box>
          {typeof window !== "undefined" && (
            <Quotes district={district} data={data} />
          )}
        </Box>
        <Box>
          {typeof window !== "undefined" && (
            <StreetView district={district} data={data} />
          )}
        </Box>
        <Waypoint
          onEnter={() => onArticlesWaypoint(true)}
          onLeave={() => onArticlesWaypoint(false)}
          // @ts-ignore
          scrollableAncestor={windowGlobal}
        >
          <Box>
            <Box>
              <Articles />
            </Box>
            <Box>
              <Footer />
            </Box>
          </Box>
        </Waypoint>

        {typeof window !== "undefined" && (
          <Flex
            css={css`
              position: fixed;
              transition: bottom 1s ease-in-out;
              bottom: ${showBottomMenu ? "0px" : "-300px"};
              left: 0;
              right: 0;
              background: ${colors.blue1};
              box-shadow: 0px 0px 20px #0004;
              z-index: 9999;
              height: 80px;
              ${mq("lg")} {
                height: 150px;
              }
            `}
            alignItems="center"
          >
            <LargeScreen>
              <Flex
                flexDirection="column"
                width={250}
                css={css`
                  background: ${colors.blue2};
                  height: 100%;
                `}
              >
                <Map
                  district={district}
                  layer={layer}
                  fromYear={fromYear}
                  toYear={toYear}
                  data={data}
                  showLabels={false}
                />
              </Flex>
            </LargeScreen>
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              flex={1}
              css={css`
                height: 100%;
                ${mq("lg")} {
                  border-left: 4px solid ${colors.blue1};
                }
              `}
            >
              <Box
                css={css`
                  /* text-align: center; */
                  font-family: proxima-nova-condensed, sans-serif;
                  font-style: italic;
                  padding: 4px 16px;
                  background: ${colors.white};
                  color: ${colors.blue1};
                `}
              >
                {/* {districts[district!].name.replace("Gebied: ", "")} */}

                <Select
                  color={colors.black}
                  css={css`
                    color: ${colors.black};
                  `}
                  value={selectOptions.find(
                    option => option.value === district
                  )}
                  options={selectOptions}
                  menuPlacement="top"
                  onChange={({ value }: any) =>
                    district &&
                    navigateTo(`/app/${value}/${layer}/${fromYear}/${toYear}`)
                  }
                />
              </Box>
              <Flex flex={1} alignItems="center">
                <Box
                  width={1}
                  css={css`
                    margin: 0 8px 0 0;
                    ${mq("lg")} {
                      margin: 0 48px;
                    }
                  `}
                >
                  <TimeSelector
                    district={district}
                    layer={layer}
                    fromYear={fromYear}
                    toYear={toYear}
                    data={data}
                    cssProp={css`
                      padding: 0;
                    `}
                  />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        )}
        {showShareDialog && (
          <ShareDialog
            district={district}
            layer={layer}
            fromYear={fromYear}
            toYear={toYear}
            data={data}
            onClose={() => setShowShareDialog(false)}
          />
        )}
      </Container>
    </Layout>
  );
};
export default App;
